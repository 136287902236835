<template>
  <div id="home">
    <NavBar :currentRoute="'ResetPassword'"/>

    <ResetPassword/>

    <About/>
  </div>
</template>

<script>
import About from "@/components/About";
import NavBar from "@/components/NavBar";
import ResetPassword from "@/components/user/ResetPassword";
import UserService from "@/service/UserService";

export default {
  name: 'ResetPasswordView',
  components: {
    NavBar,
    ResetPassword,
    About,
  },

  data: function () {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: ''
    }
  },

  methods: {
    async confirm() {
      if (this.currentPassword.length < 7 || this.newPassword.length < 7 || this.confirmPassword.length < 7) {
        this.errorMessage = 'Fjalëkalimi duhet të jepet më i gjatë!';
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "Fjalëkalimet nuk korrespondojnë!"
        return;
      }
      try {
        await UserService.updatePassword(this.currentPassword, this.newPassword);
        this.errorMessage = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.currentPassword = '';
      } catch (e) {
        this.errorMessage = e.message;
      }
    }
  }
}
</script>